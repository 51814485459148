<template>

<!--  (!isWarehouseLodz || fromPolandToUnitedKingdomDHLPL)-->
  <div class="order-create__col custom-col mb-0"
       v-if="FBM.data.isVatNumberByCountry &&
       (!isWarehouseLodz || FBM.data.visibleVatNumber) && !isWarehouseCambridge"
  >
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                          'common_vatNumberAdmin',
                        ])"></div>
      {{$t('common_vatNumberAdmin.localization_value.value')}}
    </div>

    <div class="order-create__product custom-row mt-3 flex-column">


      <div class="order-create__product-col custom-col"
           v-if="checkIsMarketplaceCompany">
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--33 custom-col--md-25 custom-col--xs-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_VatNoPaid'])"></div>
            <RadioDefault
                :label="$t('fbm_VatNoPaid.localization_value.value')"
                v-bind:class="{'ui-no-valid': FBM.data.vatNumberChoose}"
                :errorTxt="$t(`${FBM.validationTranslate.vatNumberChoose}.localization_value.value`)"
                :error="FBM.validation.vatNumberChoose"
                :name="'vatRadio'"
                @input="FBM.data.visibleVatNumber = false; FBM.data.vatNumberChoose = true"
                :value="!FBM.data.visibleVatNumber && FBM.data.vatNumberChoose"
            />
          </div>
          <div class="order-create__col custom-col custom-col--50 custom-col--md-75 custom-col--xs-100 flex-column mb-0"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_VatPaid'])"></div>
            <RadioDefault
                :label="$t('fbm_VatPaid.localization_value.value')"
                :name="'vatRadio'"
                @input="FBM.data.visibleVatNumber = true; FBM.data.vatNumberChoose = true"
                :value="FBM.data.visibleVatNumber && FBM.data.vatNumberChoose"
            />
          </div>
        </div>

      </div>

      <div class="order-create__product-col custom-col"
           v-if="!checkIsMarketplaceCompany"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                  'common_vatNumber',
                ])"></div>

        <DefaultCheckbox
                v-model="FBM.data.visibleVatNumber"
                :label="$t('common_vatNumber.localization_value.value')"
                :disabled="disabledVatNumber"
                @click="FBM.data.visibleVatNumber = !FBM.data.visibleVatNumber"
        />
      </div>


      <div class="order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-if="FBM.data.visibleVatNumber && checkIsMarketplaceCompany"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                  'FBM_marketplace',
                ])"></div>

        <DefaultSelect
            class="w-100"
            :label="$t('FBM_marketplace.localization_value.value')"
            :optionsLabel="'name'"
            :options="FBM.data.marketplaces"
            v-bind:class="{'ui-no-valid': FBM.validation.marketplace}"
            :errorTxt="$t(`${FBM.validationTranslate.marketplace}.localization_value.value`)"
            :error="FBM.validation.marketplace"
            :selected="FBM.data.marketplace"
            :clearable="false"
            @change="(val) => changeMarketplace(val)"
        />
      </div>


      <div class="order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-if="FBM.data.visibleVatNumber && checkIsMarketplaceCompany"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                  'FBM_IOSS',
                ])"></div>

        <DefaultSelect
            class="w-100"
            :label="$t('FBM_IOSS.localization_value.value')"
            :optionsLabel="'number'"
            :options="IOSSArray"
            v-bind:class="{'ui-no-valid': FBM.validation.IOSS}"
            :errorTxt="$t(`${FBM.validationTranslate.IOSS}.localization_value.value`)"
            :error="FBM.validation.IOSS"
            :selected="FBM.data.IOSS"
            @change="(val) => FBM.data.IOSS = val"
        />
      </div>


      <div class="order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-if="FBM.data.visibleVatNumber && !checkIsMarketplaceCompany"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
                  'common_vatNumber',
                ])"></div>

        <DefaultInput
                :label="$t('common_vatNumber.localization_value.value')"
                v-bind:class="{'ui-no-valid': FBM.validation.vatNumber}"
                :error="FBM.validation.vatNumber"
                :errorTxt="$t(`${FBM.validationTranslate.vatNumber}.localization_value.value`)"
                :type="'text'"
                :autocompleteOff="true"
                v-model="FBM.data.vatNumber"
        />
      </div>

      <template v-if="(FBM.data.visibleVatNumber || checkIsMarketplaceCompany) && !this.isLabel">
        <div class="order-create__col custom-col position-relative"
             v-if="FBM.data.vatNumberFile.data.maxCountFiles - Object.keys(FBM.data.vatNumberFile.data.downloadFiles).length > 0"
        >
          <DropZone class="drop-zone-bg"
                    :multiple="false"
                    :parentFiles="FBM.data.vatNumberFile.data.files"
                    :maxCount="FBM.data.vatNumberFile.data.maxCountFiles - FBM.data.vatNumberFile.data.downloadFiles.length"
                    :maxSize="FBM.data.vatNumberFile.data.maxSizeFiles"
                    :accept="'application/pdf'"
                    @changeImg="changeVatNumberFile"
          />
          <span class="error-field ml-3" v-if="FBM.data.vatNumberFile.validation.files">
            {{$t('common_fileIsRequired.localization_value.value')}}
          </span>
        </div>


        <div class="custom-col"
             v-bind:class="fileWidthClass"
             v-if="Object.keys(FBM.data.vatNumberFile.data.downloadFiles).length > 0"
        >
          <div class="custom-row product-img-row">
            <div class="custom-col custom-col--50 custom-col--md-50 custom-col--sm-100">
              <DownloadFileBlock
                  v-for="(item, index) in FBM.data.vatNumberFile.data.downloadFiles"
                  :key="index"
                  :file="item"
                  @removeFile="(id) => {removeFile(id)}"
              />
            </div>
          </div>
        </div>
      </template>

<!--      <template v-if="FBM.data.visibleVatNumber || checkIsMarketplaceCompany">-->
      <template v-if="!FBM.data.visibleVatNumber && globalCompany && checkIsRequiredVatNumber">


<!--             v-if="!FBM.data.visibleVatNumber && checkIsMarketplaceCompany"-->
<!--             v-if="!FBM.data.visibleVatNumber && checkIsRequiredVatNumber"-->
        <div class="order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_transactionAmount'])"></div>

          <DefaultInput
              :label="$t('fbm_transactionAmount.localization_value.value')"
              v-bind:class="{'ui-no-valid': FBM.validation.transactionAmount}"
              :error="FBM.validation.transactionAmount"
              :errorTxt="$t(`${FBM.validationTranslate.transactionAmount}.localization_value.value`)"
              :type="'text'"
              :disabled="setDisabledTrnsAmount"
              :autocompleteOff="true"
              v-model="FBM.data.transactionAmount"
          />
        </div>
<!--             v-if="!FBM.data.visibleVatNumber && checkIsMarketplaceCompany"-->
<!--             v-if="!FBM.data.visibleVatNumber && checkIsRequiredVatNumber"-->
        <div class="order-create__product-col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_customsClearance'])"></div>

          <DefaultInput
              :label="$t('fbm_customsClearance.localization_value.value')"
              v-bind:class="{'ui-no-valid': FBM.validation.customsClearance}"
              :error="FBM.validation.customsClearance"
              :errorTxt="$t(`${FBM.validationTranslate.customsClearance}.localization_value.value`)"
              :type="'text'"
              :disabled="true"
              :autocompleteOff="true"
              v-model="FBM.data.customsClearance"
          />
        </div>


      </template>

    </div>

  </div>
</template>

<script>
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DropZone from "../../../../../../coreComponents/DropZone/DropZone";
  // import CloseIcon from '../../../../../../../../public/img/modal-group/close-icon.svg?inline'
  import DownloadFileBlock from "../../../../../../coreComponents/DownloadFileBlock/DownloadFileBlock";
  import {
    CAMBRIDGE_WAREHOUSE,
    DPD_SHIPPING_COMPANY,
    LODZ_WAREHOUSE,
    POSTNL_SHIPPING_COMPANY, UNITED_KINGDOM_ID
  } from "@/staticData/staticVariables";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import {FBMMixins} from "@/mixins/FBMMixins/FBMMixins";
  import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";

  export default {
    name: "VatNumberBlock",
    components: {
      RadioDefault,
      DefaultSelect,
      DownloadFileBlock,
      DropZone,
      DefaultInput,
      DefaultCheckbox,
      // CloseIcon,
    },

    mixins: [FBMMixins],

    props: {
      FBM: Object,
      fileWidthClass: String,
      isAdminManage: {
        type: Boolean,
        default: false,
      },
    },

    watch: {

      calculatePrice(val) {
      // && Number(this.FBM.data.transactionAmount + '') < 0.1
        let orderItem = this.$store.getters.getOrdersFBMItem?.orderFbmInstance
        if(this.isAdminManage && orderItem?.addition_info?.info && orderItem.addition_info?.info?.tax_clearance) {
          return
        }

        if(!this.FBM.data.Order.getIssetTransaction() && Number(val) > 0) {
          this.FBM.data.transactionAmount = val
        }
      },

      getZip() {
        this.calculateCustomsClearance()
      },

      shippingPartner() {

        this.setVisibleAndRequiredVat()

        // if(this.checkIsRequiredVatNumber) {
        //   this.FBM.data.visibleVatNumber = true
        //   this.disabledVatNumber = true
        // } else {
        //   if(this.isWarehouseLodz) {
        //     this.FBM.data.visibleVatNumber = false
        //   }
        //   this.disabledVatNumber = false
        // }
        //
        // if(this.checkIsMarketplaceCompany) {
        //   this.changeIsMarketplaceCompany()
        //   // this.isRoyalMAil = true
        //   this.disabledVatNumber = false
        //   this.FBM.data.visibleVatNumber = false
        // }
        //
        // if(this.isEasyType && this.isConsolidation && this.FBM.data.isVatNumberByCountry) {
        //   this.disabledVatNumber = true
        //   this.FBM.data.visibleVatNumber = true
        // }

        ////////////////////////////////////////



        // if(this.checkIsMarketplaceCompany) {
        //   this.changeIsMarketplaceCompany()
        //   this.disabledVatNumber = true
        //   this.FBM.data.visibleVatNumber = true
        // } else {
        //   this.disabledVatNumber = false
        //   this.FBM.data.marketplace = {}
        //   if(this.FBM.data.IOSS?.id) {
        //     this.FBM.data.vatNumber = ''
        //   }
        // }

        // if(this.isWarehouseLodz && this.FBM.getTrsnAddressCountry()?.id === UNITED_KINGDOM_ID) {
        //   this.fromPolandToUnitedKingdomDHLPL = true
        //   this.FBM.data.visibleVatNumber = true
        // } else {
        //   this.fromPolandToUnitedKingdomDHLPL = false
        // }

        // else {
          // this.isRoyalMAil = false
        // }

        // else {
        //   this.disabledVatNumber = false
        //   this.FBM.data.marketplace = {}
        //   if(this.FBM.data.IOSS?.id) {
        //     this.FBM.data.vatNumber = ''
        //   }
        // }
      },

      shippingPrice() {
        this.setVisibleAndRequiredVat()
      },

      getTransactionAmount() {
        this.calculateCustomsClearance()
      },

      selectedCountry() {
        this.FBM.data.IOSS = null
      },
    },

    computed: {
      setDisabledTrnsAmount() {
        console.log(343434);
        console.log(this.checkIsMarketplaceCompany);
        console.log(this.FBM.data.Order.getIssetTransaction());
        console.log(this.isAdmin && this.checkIsMarketplaceCompany && this.FBM.data.Order.getIssetTransaction());
        if(this.isAdmin && this.checkIsMarketplaceCompany && this.FBM.data.Order.getIssetTransaction()) return false

        return this.FBM.data.Order.getIssetTransaction()
      },


      isDPD() {
        return this.FBM.data.shippingPartner.id === DPD_SHIPPING_COMPANY.id
      },

      isPostNL() {
        return this.FBM.data.shippingPartner.id === POSTNL_SHIPPING_COMPANY.id
      },


      shippingPartner() {
        return this.FBM.data.shippingPartner
      },

      shippingPrice() {
        return this.FBM.data.shippingAPIPrice
      },

      getTransactionAmount() {
        return this.FBM.data.transactionAmount
      },

      getZip() {
        let delivery = this.FBM.data.transactionAddress !== this.FBM.data.transactionAddressTypes.etsy ?
            this.FBM.data.DeliveryPayPal : this.FBM.data.DeliveryEtsy
        return delivery?.delivery?.zip
      }
    },

    data() {
      return {
        IOSSArray: [],
        disabledVatNumber: false,

        LODZ_WAREHOUSE: LODZ_WAREHOUSE,
        CAMBRIDGE_WAREHOUSE: CAMBRIDGE_WAREHOUSE,

        isRoyalMAil: false,

        // fromPolandToUnitedKingdomDHLPL: false,
      }
    },

    mounted() {
      // if(!this.checkIsMarketplaceCompany){
      //   this.FBM.data.marketplace = {}
      //   this.disabledVatNumber = false
      // } else {
      //   this.disabledVatNumber = true
      //   this.FBM.data.visibleVatNumber = true
      // }
      //
      // if(this.checkIsRequiredVatNumber) {
      //   this.disabledVatNumber = true
      //   this.FBM.data.visibleVatNumber = true
      // }

      // && Number(this.FBM.data.transactionAmount + '') < 0.1
      if(!this.FBM.data.Order.getIssetTransaction() && Number(this.calculatePrice) > 0) {
        this.setTransactionAmountWithCalculatePrice()
      }

      if(
          (this.FBM.data.Order.data.transactionType !== '') ||
          !this.FBM.data.visibleVatNumber && this.globalCompany && this.checkIsRequiredVatNumber
      ) {
        this.calculateCustomsClearance()
      }

      if(!this.FBM.data.IOSS?.id) {
        this.changeIsMarketplaceCompany()
      }

      // this.isRoyalMAil = this.checkIsMarketplaceCompany

      if(this.checkIsRequiredVatNumber && !this.checkIsMarketplaceCompany) {
        this.FBM.data.visibleVatNumber = true
        this.disabledVatNumber = true
      }

      if(this.FBM.data.IOSS?.id || (this.FBM.data.vatNumber && this.FBM.data.vatNumber.length > 0)) {
        return
      }

      if(this.checkIsMarketplaceCompany && !this.isAdminManage){
        // this.FBM.data.marketplace = {}
        // this.isRoyalMAil = true
        this.FBM.data.visibleVatNumber = false
        this.disabledVatNumber = false
      } else {
        // this.isRoyalMAil = false
        this.FBM.data.marketplace = {}
      }

      if(this.isEasyType && this.isConsolidation && this.FBM.data.isVatNumberByCountry &&
          this.FBM.getTrsnAddressCountry()?.id === UNITED_KINGDOM_ID) {
        this.disabledVatNumber = true
      }

    },

    methods: {

      setVisibleAndRequiredVat() {
        if(this.checkIsRequiredVatNumber) {
          this.FBM.data.visibleVatNumber = true
          this.disabledVatNumber = true
        } else {
          if(this.isWarehouseLodz) {
            this.FBM.data.visibleVatNumber = false
          }
          this.disabledVatNumber = false
        }

        if(this.checkIsMarketplaceCompany) {
          this.changeIsMarketplaceCompany()
          this.disabledVatNumber = false
          this.FBM.data.visibleVatNumber = false
        }

        if(this.isEasyType && this.isConsolidation && this.FBM.data.isVatNumberByCountry &&
            this.FBM.getTrsnAddressCountry()?.id === UNITED_KINGDOM_ID) {
          this.disabledVatNumber = true
          this.FBM.data.visibleVatNumber = true
        }
      },

      setTransactionAmountWithCalculatePrice() {
        let orderItem = this.$store.getters.getOrdersFBMItem?.orderFbmInstance
        if(this.isAdminManage && orderItem?.addition_info?.info && orderItem.addition_info?.info?.tax_clearance) {
          return
        }

        this.FBM.data.transactionAmount = this.calculatePrice
      },

      calculateCustomsClearance() {
        if(this.FBM.data.transactionAmount === 0) {
          this.FBM.data.customsClearance = 0
          return
        }

        // let commission = 4
        // if(!this.FBM.data.Order.getIssetTransaction()) {
        //   commission = 5
        // }
        // this.FBM.data.customsClearance = parseFloat(this.FBM.data.transactionAmount * 0.5 * 0.25 + commission)
        //     .toFixed(2)
        this.FBM.data.customsClearance = parseFloat(this.FBM.data.transactionAmount * 0.25)
            .toFixed(2)

        let delivery = this.FBM.data.transactionAddress !== this.FBM.data.transactionAddressTypes.etsy ?
            this.FBM.data.DeliveryPayPal : this.FBM.data.DeliveryEtsy
        if(
            delivery.getZip().toLowerCase().indexOf('gy') === 0 ||
            delivery.getZip().toLowerCase().indexOf('hs') === 0 ||
            delivery.getZip().toLowerCase().indexOf('im') === 0 ||
            delivery.getZip().toLowerCase().indexOf('iv') === 0 ||
            delivery.getZip().toLowerCase().indexOf('je') === 0 ||
            delivery.getZip().toLowerCase().indexOf('ka') === 0 ||
            delivery.getZip().toLowerCase().indexOf('pa') === 0 ||
            delivery.getZip().toLowerCase().indexOf('ph') === 0 ||
            delivery.getZip().toLowerCase().indexOf('po') === 0 ||
            delivery.getZip().toLowerCase().indexOf('tr') === 0
        ) {
          this.FBM.data.customsClearance = parseFloat(Number(this.FBM.data.customsClearance) * 2).toFixed(2)
        }
      },

      changeMarketplace(val){
        this.FBM.data.marketplace = val
        this.FBM.data.IOSS = null
        this.changeIsMarketplaceCompany(true)
      },

      changeIsMarketplaceCompany(changeMarketplace = false){
        if(!changeMarketplace){
          this.FBM.data.marketplace = this._.first(this.FBM.data.marketplaces)
        }

        const query = new this.Query();
        let myQuery = query.for('posts')

        let url = '?'

        let delivery = this.FBM.data.transactionAddress !== this.FBM.data.transactionAddressTypes.etsy ?
            this.FBM.data.DeliveryPayPal : this.FBM.data.DeliveryEtsy
        let country = delivery.getCountry()?.id

        myQuery.where('marketplace', this.FBM.data.marketplace.name)
        if(country) {
          myQuery.where('countryId', country)
        }
        url = url + myQuery.limit(1000000).page(1).url().split('?')[1]

        this.$store.dispatch('fetchIOSSNumbers', url).then((response) => {
          this.IOSSArray = this.getRespPaginateData(response)
          if(this.IOSSArray.length > 0){
            this.FBM.data.IOSS = this._.first(this.IOSSArray)
          } else {
            this.FBM.data.IOSS = null
          }
        })
      },

      // changeVatNumberFile(files, maxSizeError) {
      //   this.FBM.data.vatNumberFile.setFiles(files)
      //
      //   this.FBM.data.vatNumberFile.setFilesMaxSizeError(maxSizeError)
      // },

      removeFile(id) {

        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$store.dispatch('removeUserDocument', id).then(() => {
            this.FBM.removeVatFile(id)
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      changeVatNumberFile(files, maxSizeError){

        if (files.length === 0) return
        let dataFiles = []

        files.map(fileItem => {
          let file = fileItem.file
          this.getBase64(file).then(data => {
            let dataFile = Object.assign([], files[0])
            let base64 = data.split('base64,')
            dataFile.base64String = base64[1]
            dataFiles.push(dataFile)
          });
        })

        this.FBM.data.vatNumberFile.setFiles(dataFiles)
        this.FBM.data.vatNumberFile.setFilesMaxSizeError(maxSizeError)
      },
    }
  }
</script>

<style scoped>

</style>
